<template>
  <div ref="home" class="home">
    <van-sticky style="width: 100%">
      <van-nav-bar left-arrow :title="`REVIEW ${locationType === 'Parking' ? 'TICKET' : 'ORDER'}`"
        @click-left="onClickLeft">
        <template #left>
          <van-icon color="#ffffff" name="arrow-left" size="14pt" />
        </template>
      </van-nav-bar>
    </van-sticky>

    <div class="content" v-if="getFeeLoading">
      <div class="content-item">
        <div class="content-title word-break">Delivery Details</div>
        <van-field :value="orderType" clickable label="Order Type" label-width="110pt" readonly right-icon="edit"
          @click="takeMethodPicker = true" />
        <van-popup v-model="takeMethodPicker" position="bottom" round>
          <van-picker :columns="takeMethodColumns" cancel-button-text="CANCEL" confirm-button-text="OK" show-toolbar
            @cancel="takeMethodPicker = false" @confirm="onConfirmTakeMethod" />
        </van-popup>
        <!-- <van-field v-if="timeColumns.length > 0" :value="time" clickable readonly right-icon="edit" label-width="110pt" label="Deliver/Pickup Time" @click="timePicker = true" />
        <van-field v-else class="cell-close" value="Closed. Room service is not available." readonly />
        <van-popup v-model="timePicker" round position="bottom">
          <van-picker confirm-button-text="OK" cancel-button-text="CANCEL" show-toolbar :columns="timeColumns" @cancel="timePicker = false" @confirm="onConfirmTime" />
        </van-popup> -->

        <van-field v-if="showPrice" :value="payMethod" clickable label="Payment Type" label-width="110pt" readonly
          right-icon="edit" @click="payMethodPicker = true" />
        <van-popup v-model="payMethodPicker" round position="bottom">
          <van-picker show-toolbar confirm-button-text="OK" cancel-button-text="CANCEL" :columns="payMethodColumns"
            @cancel="payMethodPicker = false" @confirm="onConfirmPayMethod" />
        </van-popup>
      </div>

      <template v-for="mealPeriod in orderData">
        <div v-if="mealPeriod.shoppingFood.length > 0 && getFeeLoading" :key="mealPeriod.mealPeriodId">
          <div class="content-item">
            <div class="content-title word-break">
              {{ mealPeriod.mealPeriod }}
            </div>
            <!--              v-if="mealPeriod.timePicker.length > 0"-->
            <!--              :label="orderType == 'Delivery' ? 'Delivery Time' : 'Pickup Time'"-->
            <van-field v-if="timeColumns.length != 0" label="Requested Time" :value="mealPeriod.time" clickable
              class="bold-divider-cell" readonly label-width="40%" :right-icon="timeColumns.length != 0 ? 'edit' : ''"
              @click="timePicker = true" />
            <van-field v-if="!mealPeriod.isAllowAdvanced" label="Requested Time" :value="mealPeriod.time" clickable
              class="bold-divider-cell" readonly label-width="50%" :right-icon="timeColumns.length != 0 ? 'edit' : ''"
              @click="timePicker = true" />
            <van-field v-if="timeColumns.length === 0 && mealPeriod.isAllowAdvanced" :label="`${orderType} Time`"
              label-width="50%" class="cell-close no-time-period" value="No time available" readonly />
            <!-- :style="{'border-bottom' : index < mealPeriod.shoppingFood.length - 1 ? '1pt solid #dcdcdc' : 'none'}" -->

            <div v-for="(item, index) in mealPeriod.shoppingFood" :key="item.id" :class="{
              'item-content': true,
              'bold-divider-cell':
                index == mealPeriod.shoppingFood.length - 1 &&
                !mealPeriod.promoFee,
            }" :style="{
              padding:
                mealPeriod.shoppingFood.length === 1
                  ? '5pt 10pt'
                  : index === 0
                    ? '5pt 10pt 1pt'
                    : index === mealPeriod.shoppingFood.length - 1 &&
                      !mealPeriod.promoFee
                      ? '1pt 10pt 5pt'
                      : '1pt 10pt',
            }">
              <span class="name word-break" style="font-size: 11pt">{{ item.num + " * " }}{{ item.name }}</span>
              <span v-if="showPrice" class="price word-break">
                ${{ item.allPrice.toFixed(2) }}</span>
            </div>

            <div v-if="mealPeriod.promoFee" class="item-content bold-divider-cell" style="padding: 1pt 10pt 5pt">
              <span class="name word-break" style="font-size: 11pt">{{
                "Discount: " + mealPeriod.promoFeeName
                }}</span>
              <span v-if="showPrice" class="price word-break">-${{ mealPeriod.promoFee.toFixed(2) }}</span>
            </div>

            <div v-if="showPrice" class="item-content" style="padding: 5pt 10pt 0pt">
              <span class="name word-break" style="font-size: 11pt">Subtotal</span>
              <span class="price word-break" style="font-size: 11pt">${{ mealPeriod.subTotal.toFixed(2) }}</span>
            </div>
            <div v-for="(item, index) in mealPeriod.feeList" :key="item.id" :class="{
              'item-content': true,
              'bold-divider-cell': index == mealPeriod.feeList.length - 1,
            }" :style="{
              padding:
                index === mealPeriod.feeList.length - 1
                  ? '0pt 10pt 5pt'
                  : '0pt 10pt',
            }">
              <span v-if="showPrice" class="name word-break" style="font-size: 11pt">{{ item.fee }}</span>
              <span v-if="showPrice" class="price word-break" style="font-size: 11pt">${{ item.value.toFixed(2)
                }}</span>
            </div>
            <div v-if="showPrice" class="item-content" style="padding: 5pt 10pt">
              <span class="name word-break" style="font-weight: bold; font-size: 12pt">TOTAL</span>
              <span class="price word-break" style="font-weight: bold; font-size: 12pt; color: #242424">${{
                mealPeriod.total.toFixed(2) }}</span>
            </div>

            <van-popup v-model="timePicker" v-if="timeColumns.length != 0" position="bottom" round>
              <van-picker :columns="timeColumns" cancel-button-text="CANCEL" confirm-button-text="OK" show-toolbar
                @cancel="timePicker = false" @confirm="onConfirmTime($event, mealPeriod)" />
            </van-popup>
          </div>
        </div>
      </template>

      <div class="content-item payLater" ref="informationCard">
        <div class="content-title word-break">Guest Information</div>
        <div class="item-content" style="display: block">
          <div class="tips word-break">
            Please enter your details below. We will only use this information
            to contact you about your order and for no other purpose.
            <span v-if="isCreditcardPayment()"><br />*Name is required for Credit/Debit Card Payment and must
              match the name on the card.</span>
          </div>
          <div ref="aform">
            <!-- <div class="title">Room Number</div> -->
            <!-- <van-field
              label="Room Number"
              v-model="room"
              @click="roomPicker = true"
            /> -->
            <a-form>
              <a-form-item v-if="isShowTable()" label="Room Number" style="margin-bottom: 0pt" class="removeAfter">
                <a-select :default-active-first-option="false" :filter-option="false" :not-found-content="null"
                  :show-arrow="false" :value="chargeRoom" show-search style="width: 100%" @change="chargeRoomChange"
                  @search="chargeRoomSearch" :getPopupContainer="() => this.$refs.aform"
                  :notFoundContent="notFoundContent">
                  <a-select-option v-for="room in searchChargeRoomList" :key="room.id" :value="room.name">
                    {{ room.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item v-if="locationType != 'Parking'" :label="isShowTable() ? 'Table Number' : 'Room Number'"
                style="margin-bottom: 0pt" class="removeAfter">
                <a-select :default-active-first-option="false" :filter-option="false" :not-found-content="null"
                  :show-arrow="false" :value="roomDisplay" show-search style="width: 100%" @change="roomChange"
                  @search="roomSearch" :getPopupContainer="() => this.$refs.aform" :disabled="roomDisabled"
                  :notFoundContent="notFoundContent" ref="tableRoom">
                  <a-select-option v-for="room in searchRoomList" :key="room.id" :value="room.name">
                    {{ room.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item v-if="isCreditcardPayment()" class="removeAfter">
                <template v-slot:label> First Name<span>*</span> </template>
                <a-input v-model="firstName" ref="inputFirstName" />
              </a-form-item>
              <a-form-item class="removeAfter">
                <template v-slot:label>
                  Last Name<span v-if="isCreditcardPayment()">*</span>
                </template>
                <a-input v-model="name" ref="inputLastName" />
              </a-form-item>

              <template v-if="locationType === 'Parking'">
                <a-form-item class="removeAfter">
                  <template v-slot:label>Make/Color</template>
                  <a-input v-model="make" />
                </a-form-item>
                <a-form-item class="removeAfter">
                  <template v-slot:label>License Plate</template>
                  <a-input v-model="license" />
                </a-form-item>
                <a-form-item class="removeAfter" v-if="hotelInno!='PIAES'&&hotelInno!='piaes'">
                  <template v-slot:label></template>
                  <van-checkbox style="padding: 10px 0;" icon-size="16" v-model="licensePlateChecked"
                    :checked-color="primaryColor" shape="square">I
                    don't remember</van-checkbox>
                </a-form-item>
              </template>
              <template v-else>
                <a-form-item label="Number Of Guests" class="removeAfter">
                  <a-select v-model="guestNum" :default-value="1">
                    <a-select-option v-for="i in 50" :key="i" :value="i">
                      {{ i }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>

              <a-form-item v-if="enableText !== 'Disable'" class="removeAfter">
                <span slot="label">
                  Mobile Number<span style="font-size: 11pt"
                    v-if="enableText === 'Text(Optional)'"><br />(Optional)</span>
                </span>

                <a-input v-model="phone" @change="validateMobile(phone)" />
              </a-form-item>
              <div v-if="licensePlateChecked" style="font-style: italic;font-weight: bold;">A text will be sent to you to update your license plate#</div>
            </a-form>
          </div>

          <div v-if="posConfig.posBridge === 'HVT'" class="title">
            Additional Comments
          </div>
          <van-field v-if="posConfig.posBridge === 'HVT'" v-model="remarks" autosize class="word-break" rows="4"
            style="margin: 0pt" type="textarea" />
        </div>
      </div>

      <!--      color="#2b2e36"-->
    </div>
    <!-- <div>
          <button style="color:#ffffff; font-size:14px; font-family:Trebuchet MS; 
            font-weight:normal; background-color:#4177B4; padding:7px 16px; display:inline-block;
            border-radius:3px; border:1px solid #4A90E2; box-shadow:0 2px 4px 0 rgba(0,0,0,0.25); "
            @click="submitBuy">
          BUY NOW
          </button>
        </div> -->
    <div class="bottom" style="padding: 0 10pt; width: 100%" v-if="getFeeLoading">
      <van-button class="btn" size="large" @click="submit" style="margin: 11pt 0">SUBMIT
        {{ locationType === "Parking" ? "TICKET" : "ORDER" }}</van-button>
    </div>

    <ThankPopup ref="thankPopup" @confirm="ok" />
  </div>
</template>

<script>
import ThankPopup from "@/components/ThankPopup/index.vue";
import { mapGetters, mapActions } from "vuex";
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import moment from "moment-timezone";
import { Toast } from "vant";
import { Dialog } from "vant";
import { randomUUID } from "@/utils/utils";
// import PayWithConverge from "https://demo.convergepay.com/hosted-payments/PayWithConverge.js";

export default {
  name: "ConfirmOrder",
  components: { ThankPopup },
  data() {
    return {
      //   time: '',
      timePicker: false,
      timeColumns: [],
      orderType: "",
      takeMethodPicker: false,
      takeMethodColumns: ["Delivery", "Pickup"],
      payMethod: "",
      payMethodPicker: false,
      payMethodColumns: ["Charge To Room", "Credit/Debit Card", "Direct Bill"],
      roomPicker: false,
      roomDisplay: "",
      room: "",
      roomName: "",
      roomId: "",
      roomList: [],
      searchRoomList: [],
      firstName: "",
      name: "",
      make: "",
      license: "",
      remarks: "",
      //   total: 0,
      guestNum: 1,
      url: {
        getFee: "/adminApi/foodOrder/getFeeList",
        createNewTickets: "/api/common/hotel/data/multipleorder/create2",
        getAllMealPeriod: "/adminApi/foodOrder/getAllMealPeriod",
        orderInfoUpdate: "/api/common/hotel/data/order_info/update2",
        idWorker: "/api/common/hotel/data/idWorker",
        getRoomList: "/adminApi/htRoom/list",
        getFeeListByCategoryList:
          "/adminApi/foodOrder/getFeeListByCategoryList",
        getFoodDeliverTime:
          "/api/common/hotel/data/sub_order/getFoodDeliverTime",
        getFoodOrderNum: "/api/common/hotel/data/sub_order/getFoodOrderNum",
        getOrderType: "/adminApi/omnivore/orderTypesByLocation",
        getTableList: "/adminApi/omnivore/table",
        openTicket: "/adminApi/omnivore/openTicket",
        getPaymentTypeList: "/adminApi/omnivore/tenderTypesByLocation",
        getElavonToken: "/adminApi/elavon/getToken",
        logMessage: "/adminApi/elavon/logMessage",
        getAllTextResponseByHotelId:
          "/adminApi/foodOrder/getAllTextResponseByHotelId",
      },
      feeList: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      mealPeriodList: [],
      orderId: "",
      orderTime: "",
      orderData: [],
      hotelId: "",
      posConfig: JSON.parse(localStorage.getItem("posConfig")) || {
        posBridge: "HVT",
      },
      orderTypeList: JSON.parse(localStorage.getItem("orderTypeList")) || [],
      categoryIdList: [],
      paymentTypeList:
        JSON.parse(localStorage.getItem("paymentTypeList")) || [],
      oScript: null,
      saveLoading: false,
      testAmount: 0,
      locationType: "Guest Room",
      roomDisabled: false, //房间选择禁用
      chargeToRoomList: [],
      searchChargeRoomList: [],
      chargeRoom: "",
      enableText: "Disable", // "Text(Optional)" / "Text(Required)" / "Disable"
      phone: "",
      textResponseList: [],
      getFeeLoading: false,
      showPrice: true,
      notFoundContent: "",
      requests: [],
      queryRoom: "",
      licensePlateChecked: false,
      hotelInno: localStorage.getItem("hotelName")
    };
  },
  watch: {
    licensePlateChecked() {
      if (this.licensePlateChecked) {
        this.license = "To be updated";
      } else {
        this.license = "";
      }
    }
    // searchRoomList() {
    //   if (this.searchRoomList.length == 1) {
    //     this.room == this.searchRoomList[0].id;
    //     console.log(this.room);
    //   }
    // },
  },
  mounted() {
    // this.oScript = document.createElement("script");
    // this.oScript.type = "text/javascript";
    // this.oScript.src =
    //   "https://api.demo.convergepay.com/hosted-payments/PayWithConverge.js";
    // this.oScript.async = true;
    // document.body.appendChild(this.oScript);
    // console.log(this.oScript);
    // const script = document.createElement("script");
    // script.type = "text/javascript";
    // script.src =
    //   "https://api.demo.convergepay.com/hosted-payments/PayWithConverge.js";
    // document.body.appendChild(script);
    if (this.$route.query && this.$route.query.room) {
      this.queryRoom = this.$route.query.room;
    }
  },
  computed: {
    ...mapGetters({
      shoppingFood: "foods", //购物车中的餐品
      mealPeriodOpenTime: "mealPeriodOpenTime", //锁点餐品最近两天的开放时间段
      discount: "discount", //折扣信息
      primaryColor: "primaryColor",
    }),
  },
  created() {
    console.log("this.$route.query", this.$route.query);
    if (window.sessionStorage.getItem("paymentStorageDara")) {
      // location 跳转支付返回
      Object.assign(
        this.$data,
        JSON.parse(window.sessionStorage.getItem("paymentStorageDara"))
      );

      if (this.$route.query.ssl_result_message === "APPROVAL") {
        for (let i = 0; i < this.requests.length; i++) {
          let req = this.requests[i];
          // req.sslTxnId = "160624C45-47423506-3A2F-40BB-8CEA-9E75D9D06472";
          req.sslTxnId = this.$route.query.ssl_txn_id;
        }
        this.openTicket(this.requests);
      }
      return;
    }
    if (this.$route.query.room) {
      this.room = this.$route.query.room;
      this.roomDisplay = this.$route.query.room;
      this.roomDisabled = true;
    }
    if (this.$route.query.location) {
      this.locationType = this.$route.query.location;
    }

    if (this.shoppingFood.length == 0) {
      let query = {
        hotelname: localStorage.getItem("hotelName"),
      };
      if (this.room) {
        query.room = this.room;
      }
      if (this.locationType) {
        query.location = this.locationType;
      }
      this.$router.replace({
        path: "/",
        query: query,
      });
      // window.location.href = `https://${window.location.host}/#/?hotelname=${query.hotelname}&room=${query.room}&locationType=${query.location}`;
    }
    this.hotelId = localStorage.getItem("hotelId");
    //获取所有房间=====charge to room 特殊处理
    // postAction(this.url.getRoomList, {
    //   category: [],
    //   current: 1,
    //   hotelId: localStorage.getItem("hotelId"),
    //   name: "",
    //   notes: "",
    //   size: 9999,
    //   type: "guest",
    // }).then((res) => {
    //   this.chargeToRoomList = res.data.records;
    // });

    if (this.posConfig.posBridge == "HVT") {
      this.orderType = this.takeMethodColumns[0];
      this.payMethod = "Charge To Room";
      this.getRoomList();
      this.getDeliveryTime();
    } else {
      this.getOrderType();

      this.getTableList();

      let chargeType = sessionStorage.getItem("chargeType"); // 判断时候url参数传入了 chargeType
      if (chargeType) {
        this.payMethod = chargeType;
        this.payMethodColumns = [chargeType];
      } else {
        this.getPaymentType();
      }
    }
    if (
      localStorage.getItem("hotelName") == "pmont" ||
      localStorage.getItem("hotelName") == "PMONT"
    ) {
      this.showPrice = false;
    }
    // getAction(
    //   `${this.url.getAllTextResponseByHotelId}?hotelId=${this.hotelId}`
    // ).then((res) => {
    //   if (res.msg == "SUCCESS") {
    //     this.textResponseList = res.data;
    //   }
    // });
  },
  methods: {
    validateMobile(val) {
      //美式mobile 号码格式，3-3-4格式
      //代码参照https://github.com/wuhao000/antd-mobile-vue/blob/master/src/packages/input/src/index.tsx
      let ctrlValue = val;
      ctrlValue = val.replace(/\D/g, "").substring(0, 10);
      const valueLen = ctrlValue.length;
      if (valueLen > 3 && valueLen < 7) {
        ctrlValue = `${ctrlValue.substr(0, 3)}-${ctrlValue.substr(3)}`;
      } else if (valueLen >= 7) {
        ctrlValue = `${ctrlValue.substr(0, 3)}-${ctrlValue.substr(
          3,
          3
        )}-${ctrlValue.substr(6)}`;
      }
      this.phone = ctrlValue;
    },
    isShowTable() {
      if (this.posConfig.posBridge != "HVT") {
        if (
          this.payMethod.toLowerCase() == "charge to room" ||
          this.payMethod.toLowerCase() == "charge room"
        ) {
          if (
            this.locationType != "Guest Room" &&
            this.locationType != "checkmenu" &&
            this.locationType != "Meeting Room"
          ) {
            return true;
          }
        }
      }
      return false;
    },
    moment,
    ...mapActions(["addOrder", "removeDiscount"]),
    isCreditcardPayment() {
      if (this.posConfig.posBridge != "HVT") {
        let target = this.paymentTypeList.find((item) => {
          return item.displayName == this.payMethod;
        });
        if (target) {
          if (
            target.name == "Credit/Debit Card" ||
            target.displayName == "Credit/Debit Card"
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getPaymentType() {
      let location = "Guest Room";
      if (this.locationType) {
        location = this.locationType;
      }
      getAction(
        `${this.url.getPaymentTypeList}?hotelId=${this.hotelId}&locationType=${location}&mealPeriodId=${this.shoppingFood[0].mealPeriodId}`
      ).then((res) => {
        if (res.msg == "SUCCESS") {
          this.paymentTypeList = res.data;
          this.paymentTypeList.sort((a, b) => {
            return a.sort - b.sort;
          });
          this.payMethodColumns = [];
          this.paymentTypeList.forEach((item) => {
            if (item.status) {
              this.payMethodColumns.push(item.displayName);
            }
          });
          this.paymentTypeList.sort((a, b) => a.sort - b.sort);
          this.payMethod = this.payMethodColumns[0];
        }
      });
    },
    getTableList() {
      getAction(
        `${this.url.getTableList}?hotelId=${this.hotelId}&locationType=${this.locationType}`
      ).then((res) => {
        this.roomList = res.data;
        this.roomDisplay = this.getRoomDisplayName();
        this.roomList.sort((a, b) => {
          return a.number - b.number;
        });
        //chargeToRoomList取值roomList中type为guest
        this.chargeToRoomList = this.roomList.filter((item) => {
          return item.type == "guest";
        });
      });
    },
    getRoomList() {
      postAction(this.url.getRoomList, {
        category: [],
        current: 1,
        hotelId: localStorage.getItem("hotelId"),
        name: "",
        notes: "",
        size: 9999,
        type: "guest",
      }).then((res) => {
        this.roomList = res.data.records;
        this.chargeToRoomList = this.roomList;
      });
    },
    getOrderType() {
      let location = "Guest Room";
      if (this.locationType) {
        location = this.locationType;
      }
      getAction(
        `${this.url.getOrderType}?hotelId=${this.hotelId}&locationType=${location}&mealPeriodId=${this.shoppingFood[0].mealPeriodId}`
      ).then((res) => {
        this.orderTypeList = res.data;
        localStorage.setItem(
          "orderTypeList",
          JSON.stringify(this.orderTypeList)
        );
        this.setOrderTypeColumns();
        this.getDeliveryTime();
      });
    },
    setOrderTypeColumns() {
      this.takeMethodColumns = [];
      this.orderTypeList.forEach((item) => {
        if (item.status) {
          this.takeMethodColumns.push(item.displayName);
        }
      });
      this.takeMethodColumns.sort((a, b) => a.sort - b.sort);

      this.orderType = this.takeMethodColumns[0];
      // console.log(this.takeMethodColumns)
    },
    getSize(item) {
      if (item.isFull) {
        if (item.fullName) {
          return "(" + item.fullName + ")";
        } else {
          return "";
        }
      } else {
        if (item.halfName) {
          return "(" + item.halfName + ")";
        } else {
          return "";
        }
      }
    },
    getAllMealPeriodList() {
      let param = {
        hotelId: localStorage.getItem("hotelId"),
      };
      getAction(this.url.getAllMealPeriod, param).then((res) => {
        if (res.msg == "SUCCESS") {
          this.mealPeriodList = res.data;
        }
      });
    },
    async getDeliveryTime() {
      //按照类别选择送餐时间
      //重新构造数据
      this.orderData = [];
      //筛选已选mealPeriod
      let newMealArr = [];
      this.timeColumns = [];
      this.mealPeriodOpenTime.forEach((item) => {
        if (
          this.shoppingFood.findIndex(
            (food) => item.mealPeriodId == food.mealPeriodId
          ) != -1
        ) {
          newMealArr.push(item);
          this.enableText = item.enableText;
        }
      });

      for (let i = 0; i < newMealArr.length; i++) {
        const item = newMealArr[i];

        // 如果不支持提前点餐
        if (!item.isAllowAdvanced) {
          let obj = {
            id: randomUUID(),
            mealPeriod: item.mealPeriod,
            mealPeriodId: item.mealPeriodId,
            timeColumns: [],
            time: "",
            timePicker: false,
            shoppingFood: [],
            feeList: [],
            subTotal: 0,
            total: 0,
            nowOrderNum: 0,
            ticketTime: "",
            isAllowAdvanced: false,
          };
          //构造该类别的餐品
          for (let j = 0; j < this.shoppingFood.length; j++) {
            const food = this.shoppingFood[j];
            if (item.mealPeriodId == food.mealPeriodId) {
              obj.shoppingFood.push(food);
            }
          }

          await getAction(
            `${this.url.getFoodOrderNum}?hotelId=${this.hotelId}&mealPeriod=${obj.mealPeriod}`
          ).then((res) => {
            obj.nowOrderNum = res.data;
            let notAdvancedConfigList = item.notAdvancedConfigList;
            let firstBiggerThanData =
              notAdvancedConfigList.find((item) => item.maxNum > res.data) ||
              notAdvancedConfigList[notAdvancedConfigList.length - 1];
            //如果firstBiggerThanData.waitTime=ASAP 则 obj.time = ASAP
            if(firstBiggerThanData.waitTime == "ASAP"){
              obj.time = `ASAP`;
            } else {
              obj.time = `ASAP (Est. ${firstBiggerThanData.waitTime})`;
            }
            obj.ticketTime = firstBiggerThanData.ticketTime;
          });

          this.orderData.push(obj);
          //进入下一次循环
          continue;
        }

        //如果支持提前点餐
        let intervalTime = 15;
        // if (item.tips.search('Not available') != -1) {
        //   intervalTime = Number(item.advancedConfig.closeStatus)
        // }
        let obj = {
          id: randomUUID(),
          mealPeriod: item.mealPeriod,
          mealPeriodId: item.mealPeriodId,
          timeColumns: [],
          time: "",
          timePicker: false,
          shoppingFood: [],
          feeList: [],
          subTotal: 0,
          total: 0,
          isAllowAdvanced: true,
        };
        //构造该类别的餐品
        for (let j = 0; j < this.shoppingFood.length; j++) {
          const food = this.shoppingFood[j];
          if (item.mealPeriodId == food.mealPeriodId) {
            obj.shoppingFood.push(food);
          }
        }

        //构造送餐时间
        let now = moment(new Date()).tz(localStorage.getItem("timeZone"));
        let asap = moment(now.valueOf() + 1000 * 60 * intervalTime).tz(
          localStorage.getItem("timeZone")
        );
        let date = now.format("MM/DD/YYYY");
        let tomorrow = moment(now.valueOf() + 1000 * 60 * 60 * 24)
          .tz(localStorage.getItem("timeZone"))
          .format("MM/DD/YYYY");

        let is24Available = false;
        if (item.mealPeriodOpenTimeArr.length > 0) {
          is24Available = item.mealPeriodOpenTimeArr[0].is24Available;
        }
        let isCrossDay = false;
        if (item.mealPeriodOpenTimeArr.length > 0) {
          isCrossDay = item.mealPeriodOpenTimeArr[0].isCrossDay;
        }
        console.log(item.mealPeriodOpenTimeArr);
        for (let i = 0; i < item.mealPeriodOpenTimeArr.length; i++) {
          const condition = item.mealPeriodOpenTimeArr[i];
          let startTime = moment.tz(
            moment(condition.date + " " + condition.fromTime).format(
              "YYYY-MM-DD HH:mm"
            ),
            localStorage.getItem("timeZone")
          );
          let endTime = moment.tz(
            moment(condition.date + " " + condition.toTime).format(
              "YYYY-MM-DD HH:mm"
            ),
            localStorage.getItem("timeZone")
          );

          let closeStatus = item.advancedConfig.closeStatus;
          //今天
          if (now.format("YYYY/MM/DD") == condition.date) {
            //如果最小送餐时间是1440分钟时只能点tomorrow
            if (closeStatus == 1440) {
              continue;
            }
            //只有该时间段还未过去
            if (now.valueOf() < endTime.valueOf()) {
              if (this.timeColumns.length == 0) {
                let deliver = {
                  text: date,
                  children: [],
                };
                this.timeColumns.push(deliver);
              }
              let beginTime = ""; //起始时间

              //确定是否从now开始
              let a = now.valueOf() + closeStatus * 1000 * 60;

              if (startTime.valueOf() < now.valueOf()) {
                var minute = moment(a).format("mm");
                if (Number(minute) >= 45) {
                  let chazhi = 60 - Number(minute);
                  beginTime = a.valueOf() + 1000 * 60 * chazhi;
                } else if (Number(minute) >= 30) {
                  let chazhi = 45 - Number(minute);
                  beginTime = a.valueOf() + 1000 * 60 * chazhi;
                } else if (Number(minute) >= 15) {
                  let chazhi = 30 - Number(minute);
                  beginTime = a.valueOf() + 1000 * 60 * chazhi;
                } else {
                  let chazhi = 15 - Number(minute);
                  beginTime = a.valueOf() + 1000 * 60 * chazhi;
                }
              } else {
                beginTime = startTime.valueOf() + 1000 * 60 * intervalTime;
              }

              //不管什么情况都加上第一个
              this.timeColumns[0].children.push({
                text: moment(beginTime)
                  .tz(localStorage.getItem("timeZone"))
                  .format("h:mm A"),
              });
              beginTime += 1000 * 60 * intervalTime;
              while (beginTime <= endTime.valueOf()) {
                this.timeColumns[0].children.push({
                  text: moment(beginTime)
                    .tz(localStorage.getItem("timeZone"))
                    .format("h:mm A"),
                });
                beginTime += 1000 * 60 * intervalTime;
              }

              if (
                now.valueOf() <= endTime.valueOf() &&
                now.valueOf() >= endTime.valueOf() - 1000 * 60 * 10
              ) {
                this.timeColumns.shift();
              }
            }
          }
          //明天
          else if (
            moment(now.valueOf() + 1000 * 60 * 60 * 24)
              .tz(localStorage.getItem("timeZone"))
              .format("YYYY/MM/DD") == condition.date
          ) {
            let target = this.timeColumns.find((item) => {
              return item.text == tomorrow;
            });
            if (!target) {
              let deliver = {
                text: tomorrow,
                children: [],
              };
              this.timeColumns.push(deliver);
            }

            //如果24小时供餐或者跨天供餐，从12:00Am 开始
            let beginTime = startTime.valueOf();
            if (!is24Available && !isCrossDay) {
              beginTime += 1000 * 60 * intervalTime;
            }
            while (beginTime <= endTime.valueOf()) {
              this.timeColumns[this.timeColumns.length - 1].children.push({
                text: moment(beginTime)
                  .tz(localStorage.getItem("timeZone"))
                  .format("h:mm A"),
              });
              beginTime += 1000 * 60 * intervalTime;
            }
          }
          //其他日期
          else {
            let target = this.timeColumns.find((item) => {
              return item.text == moment(condition.date).format("MM/DD/YYYY");
            });
            if (!target) {
              let deliver = {
                text: moment(condition.date).format("MM/DD/YYYY"),
                children: [],
              };
              this.timeColumns.push(deliver);
            }

            //如果24小时供餐或者跨天供餐，从12:00Am 开始
            let beginTime = startTime.valueOf();
            if (!is24Available && !isCrossDay) {
              beginTime += 1000 * 60 * intervalTime;
            }
            while (beginTime <= endTime.valueOf()) {
              this.timeColumns[this.timeColumns.length - 1].children.push({
                text: moment(beginTime)
                  .tz(localStorage.getItem("timeZone"))
                  .format("h:mm A"),
              });
              beginTime += 1000 * 60 * intervalTime;
            }
          }
        }
        if (this.timeColumns.length > 0) {
          if (this.timeColumns[0].text == "Today") {
            if (this.timeColumns[0].children[0].text == "ASAP (30 Mins)") {
              this.time = this.timeColumns[0].children[0].text;
            } else {
              obj.time =
                this.timeColumns[0].text +
                " " +
                this.timeColumns[0].children[0].text;
            }
          } else {
            obj.time =
              this.timeColumns[0].text +
              " " +
              this.timeColumns[0].children[0].text;
          }
        }
        if (obj.shoppingFood.length > 0) {
          await postAction(
            `${this.url.getFoodDeliverTime}?hotelId=${this.hotelId}&mealPeriod=${obj.mealPeriod}&capacity=${item.advancedConfig.capacity}`,
            this.timeColumns
          ).then((res) => {
            if (res.success) {
              if (res.data.length === 0) {
                this.timeColumns = [];
                obj.time = "";
                this.orderData.push(obj);
              } else {
                for (let i = 0; i < res.data.length; i++) {
                  res.data[i].text =
                    res.data[i].text == date
                      ? "Today"
                      : res.data[i].text == tomorrow
                        ? "Tomorrow"
                        : res.data[i].text;
                }
                this.timeColumns = res.data || [];
                obj.time = `${this.timeColumns[0].text} ${this.timeColumns[0].children[0].text}`;
                this.orderData.push(obj);
              }
            }
          });
        }
      }
      //根据category计算税率
      //获取所有categoryId
      let categoryIdList = [];
      for (let i = 0; i < this.orderData.length; i++) {
        let item = this.orderData[i];
        for (let j = 0; j < item.shoppingFood.length; j++) {
          let food = item.shoppingFood[j];
          if (!categoryIdList.includes(food.categoryId)) {
            categoryIdList.push(food.categoryId);
          }
        }
      }

      // NEW FEE
      this.categoryIdList = categoryIdList;
      this.getFEE();
    },
    getFEE() {
      this.getFeeLoading = false;
      let categoryIdList = this.categoryIdList;
      if (categoryIdList.length > 0) {
        postAction(
          `${this.url.getFeeListByCategoryList}/${this.hotelId}`,
          categoryIdList
        ).then((res) => {
          if (res.msg == "SUCCESS") {
            for (let i = 0; i < this.orderData.length; i++) {
              this.orderData[i].subTotal = 0;
              this.orderData[i].total = 0;
              let item = this.orderData[i];
              if (item.feeList.length != 0) {
                this.orderData[i].feeList = [];
              }
              if (this.discount) {
                item.promoFee = 0;
                item.promoFeeName = this.discount.name;
              }

              //category相等的菜  一起计算税费  最后相同税费相加   被applicableTax标记的$类型的税费只计算一次
              let categoryPriceList = []; //用于税费计算，存储每个category下所有菜品的总价格
              for (let j = 0; j < item.shoppingFood.length; j++) {
                let food = item.shoppingFood[j];
                let foodPromoPrice = food.allPrice; //折扣后的总价
                //所有菜品的subTotal
                if (
                  this.discount &&
                  this.discount.type == "percent" &&
                  (this.discount.itemizer.includes(food.accountingType) ||
                    (food.accountingType == "Other" &&
                      this.discount.itemizer.includes("Misc")))
                ) {
                  if (this.discount.openValue) {
                    foodPromoPrice -=
                      (Number(this.discount.openValue) / 100) * food.allPrice;
                    item.promoFee +=
                      (Number(this.discount.openValue) / 100) * food.allPrice;
                    item.subTotal += foodPromoPrice;
                  } else {
                    foodPromoPrice -=
                      (Number(this.discount.value) / 100) * food.allPrice;
                    item.promoFee +=
                      (Number(this.discount.value) / 100) * food.allPrice;
                    item.subTotal += foodPromoPrice;
                  }
                } else {
                  item.subTotal += food.allPrice;
                }
                let target = categoryPriceList.find((i) => {
                  return i.categoryId == food.categoryId;
                });
                if (target) {
                  target.allPrice += food.allPrice;
                  target.foodPromoPrice += foodPromoPrice;
                } else {
                  let obj = {
                    categoryId: food.categoryId,
                    allPrice: food.allPrice,
                    foodPromoPrice: foodPromoPrice, //折扣后的总价
                    feeList: [], //每种catrgory的税费
                  };
                  categoryPriceList.push(obj);
                }
              }

              //记录计算过得被applicableTax标记的$类型的税费
              let isCalcFeeArr = [];

              for (let j = 0; j < categoryPriceList.length; j++) {
                let element = categoryPriceList[j];
                //该类餐品应收的税费项目
                let categoryFeeList = res.data.find((e) => {
                  return e.categoryId == element.categoryId;
                }).feeList;

                //%的税费加算,$的税费固定收一次
                //第一遍计算所有税费
                //第二遍计算applicableTax的税费，ruler为  值为applicableTax的税项 = 税项已有的值 + 被applicableTax标记的税费 * 税项的税率
                for (let k = 0; k < categoryFeeList.length; k++) {
                  let tax = categoryFeeList[k];

                  if (tax.type == "Fee") {
                    if (this.posConfig.posBridge == "HVT") {
                      if (tax.chargeBase != this.orderType) {
                        continue;
                      }
                    } else {
                      if (
                        tax.chargeBase !=
                        this.orderTypeList.find(
                          (e) => e.displayName == this.orderType
                        ).id
                      ) {
                        continue;
                      }
                    }
                  }

                  let target = element.feeList.find((e) => {
                    return e.id == tax.id;
                  });

                  if (target) {
                    if (tax.unit != "$") {
                      if (tax.type == "Tax") {
                        target.value =
                          target.value +
                          element.foodPromoPrice * Number(tax.value) * 0.01;
                      } else {
                        target.value =
                          target.value +
                          element.allPrice * Number(tax.value) * 0.01;
                      }
                    }
                  } else {
                    let temp = {
                      id: tax.id,
                      fee: tax.fee,
                      unit: tax.unit,
                      feeValue: tax.value, //税率的值，value是计算后的实际值
                      type: tax.type,
                      omnId: tax.omnId,
                      open: tax.open,
                    };
                    if (tax.unit == "$") {
                      temp.value = Number(tax.value);
                    } else {
                      let newValue = Number(tax.value).toFixed(4);
                      //如果最后两位都是00，则保留2位，如果最后一位是0，则保留3位，否则保留4位
                      if (newValue.substr(-2, 2) == "00") {
                        temp.fee =
                          tax.fee + " (" + Number(tax.value).toFixed(2) + "%)";
                      } else if (newValue.substr(-1, 1) == "0") {
                        temp.fee =
                          tax.fee + " (" + Number(tax.value).toFixed(3) + "%)";
                      } else {
                        temp.fee =
                          tax.fee + " (" + Number(tax.value).toFixed(4) + "%)";
                      }

                      if (tax.type == "Tax") {
                        temp.value =
                          element.foodPromoPrice * Number(tax.value) * 0.01;
                      } else {
                        temp.value =
                          element.allPrice * Number(tax.value) * 0.01;
                      }
                    }
                    element.feeList.push(temp);
                  }
                }
                for (let k = 0; k < categoryFeeList.length; k++) {
                  let tax = categoryFeeList[k];
                  if (tax.taxAble) {
                    //id值为applicableTax的税项
                    // let target = element.feeList.find((e) => {
                    //   return e.id == tax.applicableTax;
                    // });
                    //改为第一个是基础税费的税项
                    let target = element.feeList.find((e) => {
                      return !e.taxAble && e.type == "Tax";
                    });

                    //被applicableTax标记的税项
                    let targetTax = element.feeList.find((e) => {
                      return e.id == tax.id;
                    });

                    if (target && targetTax) {
                      //计算过得被applicableTax标记的税项
                      let isCalcFee = isCalcFeeArr.find((e) => {
                        return e == targetTax.id;
                      });
                      if (!isCalcFee) {
                        if (target.unit == "%") {
                          target.value +=
                            targetTax.value * Number(target.feeValue) * 0.01;
                          if (targetTax.unit == "$") {
                            isCalcFeeArr.push(targetTax.id);
                          }
                        }
                      }
                    }
                  }
                }
              }

              //所有相同税项相加，$类型不做处理
              // alert(JSON.stringify(categoryPriceList[0].feeList));
              for (let j = 0; j < categoryPriceList.length; j++) {
                let element = categoryPriceList[j];
                // alert(JSON.stringify(element.feeList));
                for (let k = 0; k < element.feeList.length; k++) {
                  let f = element.feeList[k];

                  //查询所有税费列表是否含有该税费

                  let target = item.feeList.find((e) => {
                    return e.id == f.id;
                  });

                  if (!target) {
                    item.feeList.push(f);
                  } else {
                    if (f.unit != "$") {
                      target.value += f.value;
                    }
                  }
                }
              }

              //计算总价
              for (let j = 0; j < item.feeList.length; j++) {
                const element = item.feeList[j];
                item.total += element.value;
              }

              item.total += item.subTotal;

              if (this.discount) {
                if (this.discount.type == "dollar") {
                  let flag = false; //判断订单中的菜品是否需要满足固定折扣的要求
                  for (let j = 0; j < item.shoppingFood.length; j++) {
                    let food = item.shoppingFood[j];
                    if (
                      this.discount.itemizer.includes(food.accountingType) ||
                      (food.accountingType == "Other" &&
                        this.discount.itemizer.includes("Misc"))
                    ) {
                      flag = true;
                    }
                  }
                  if (flag) {
                    if (this.discount.openValue) {
                      item.promoFee = Number(this.discount.openValue);
                    } else {
                      item.promoFee = Number(this.discount.value);
                    }
                    item.subTotal -= item.promoFee;
                    item.total -= item.promoFee;
                  }
                }
              }
            }
            //保留两位小数
            for (let i = 0; i < this.orderData.length; i++) {
              let item = this.orderData[i];
              item.subTotal = Number(item.subTotal.toFixed(2));
              if (item.promoFee) {
                item.promoFee = Number(item.promoFee.toFixed(2));
              }
              item.total = Number(item.total.toFixed(2));
              for (let j = 0; j < item.feeList.length; j++) {
                let fee = item.feeList[j];
                fee.value = Number(fee.value.toFixed(2));
              }
            }
            this.getFeeLoading = true;
          }
        });
      }
    },
    roomSearch(value) {
      if (value) {
        value = value.toUpperCase();
        this.searchRoomList = this.roomList.filter((room) => {
          if (room.abbreviation) {
            room.name = room.abbreviation;
            return room.abbreviation.toUpperCase().search(value) != -1;
          } else {
            return room.name.toUpperCase().search(value) != -1;
          }
        });
        if (this.searchRoomList.length == 1) {
          this.room = this.searchRoomList[0].name;
          this.roomDisplay = this.searchRoomList[0].name;
          // document.querySelector(":focus").blur();
          if (this.$refs.inputFirstName) {
            this.$refs.inputFirstName.focus();
          } else {
            this.$refs.inputLastName.focus();
          }
        } else if (this.searchRoomList.length == 0) {
          // this.room = value;
          this.room = "";
          this.notFoundContent = "Invalid Room#";
          // if (this.$refs.inputFirstName) {
          //   this.$refs.inputFirstName.focus();
          // } else {
          //   this.$refs.inputLastName.focus();
          // }
        }
      } else {
        this.searchRoomList = [];
        this.notFoundContent = "";
      }
    },
    roomChange(value) {
      this.room = value;
      this.roomDisplay = value;
    },
    chargeRoomSearch(value) {
      if (value) {
        value = value.toUpperCase();
        this.searchChargeRoomList = this.chargeToRoomList.filter((room) => {
          return room.name.toUpperCase().search(value) != -1;
        });
        if (this.searchChargeRoomList.length == 1) {
          this.chargeRoom = this.searchChargeRoomList[0].name;
          if (this.room) {
            if (this.$refs.inputFirstName) {
              this.$refs.inputFirstName.focus();
            } else {
              this.$refs.inputLastName.focus();
            }
          } else {
            this.$refs.tableRoom.focus();
          }
        } else if (this.searchChargeRoomList.length == 0) {
          // this.chargeRoom = value;
          this.chargeRoom = "";
          this.notFoundContent = "Invalid Room#";
          // if (this.room) {
          //   if (this.$refs.inputFirstName) {
          //     this.$refs.inputFirstName.focus();
          //   } else {
          //     this.$refs.inputLastName.focus();
          //   }
          // } else {
          //   this.$refs.tableRoom.focus();
          // }
        }
      } else {
        this.searchChargeRoomList = [];
        this.notFoundContent = "";
      }
    },
    chargeRoomChange(value) {
      this.chargeRoom = value;
    },
    onClickLeft() {
      let query = {
        hotelname: localStorage.getItem("hotelName"),
      };
      if (this.queryRoom) {
        query.room = this.queryRoom;
      }
      if (this.locationType) {
        query.location = this.locationType;
      }
      this.$router.push({
        path: "/placeOrder",
        query: query,
      });
    },
    onConfirmTime(value, item) {
      if (value && value.length > 0) {
        if (value[0] == "Today") {
          if (value[1] == "ASAP (30 Mins)") {
            item.time = value[1];
          } else {
            item.time = value[0] + " " + value[1];
          }
        } else {
          item.time = value[0] + " " + value[1];
        }
      }
      this.timePicker = false;
    },
    onConfirmTakeMethod(value) {
      this.orderType = value;
      this.takeMethodPicker = false;
      this.getFEE();
    },
    onConfirmPayMethod(value) {
      this.payMethod = value;
      this.payMethodPicker = false;
    },
    async submit() {
      if (
        this.orderData.some((item) => {
          return item.isAllowAdvanced;
        }).length == 0 &&
        this.timeColumns.length == 0
      ) {
        Toast({
          message: `Sorry, no time period available.`,
          className: "toast",
          duration: 3000,
          getContainer: () => {
            return this.$refs.home;
          },
        });
        return;
      }

      if (!this.room) {
        Toast({
          message: `Please enter your Room Number`,
          className: "toast",
          duration: 3000,
          getContainer: () => {
            return this.$refs.home;
          },
        });
        return;
      }
      let target = this.roomList.find(
        (item) => item.name.toUpperCase() == this.room.toUpperCase()
      );
      if (!target) {
        Toast({
          message: `Please enter valid room number`,
          className: "toast",
          duration: 3000,
          getContainer: () => {
            return this.$refs.home;
          },
        });
        return;
      }

      if (this.isCreditcardPayment()) {
        if (!this.firstName) {
          Toast({
            message: `Please enter your First Name`,
            className: "toast",
            duration: 3000,
            getContainer: () => {
              return this.$refs.home;
            },
          });
          return;
        }
      }
      if (!this.name) {
        Toast({
          message: `Please enter your Last Name`,
          className: "toast",
          duration: 3000,
          getContainer: () => {
            return this.$refs.home;
          },
        });
        return;
      }
      if (this.locationType === "Parking" && !this.make) {
        Toast({
          message: `Please input Make/Color`,
          className: "toast",
          duration: 3000,
          getContainer: () => {
            return this.$refs.home;
          },
        });
        return;
      }
      if (this.enableText === "Text(Required)" && !this.phone) {
        // 必填
        Toast({
          message: `Please input your phone number.`,
          className: "toast",
          duration: 3000,
          getContainer: () => {
            return this.$refs.home;
          },
        });
        return;
      }
      if (this.phone && this.phone.length != 12) {
        Toast({
          message: `Phone number should be 10 digit number.`,
          className: "toast",
          duration: 3000,
          getContainer: () => {
            return this.$refs.home;
          },
        });
        return;
      }

      if (!this.saveLoading) {
        this.submitOrder();
      }
    },
    async submitOrder() {
      this.saveLoading = true;
      let now = new Date();
      let createTime = moment(now).tz(localStorage.getItem("timeZone"));
      this.orderTime = createTime.format("YYYY/MM/DD h:mm A");
      // this.orderTime = createTime.format("YYYY-MM-DD hh:mm:ss");
      let today = createTime.format("YYYY/MM/DD");
      let tomorrow = moment(createTime.valueOf() + 1000 * 60 * 60 * 24)
        .tz(localStorage.getItem("timeZone"))
        .format("YYYY/MM/DD");
      this.orderId = "";
      if (this.posConfig.posBridge == "HVT") {
        await getAction(this.url.idWorker)
          .then((res) => {
            if (res.success) {
              this.orderId = res.data;
              this.saveLoading = false;
            } else {
              this.saveLoading = false;
            }
          })
          .catch((err) => {
            this.saveLoading = false;
          });
      }
      // await postAction(this.url.createNewTickets).then((res) => {
      //   this.orderId = res.data
      // })

      //orderData  shoppingfood去掉多余字段
      for (let i = 0; i < this.orderData.length; i++) {
        let item = this.orderData[i];
        for (let j = 0; j < item.shoppingFood.length; j++) {
          let shoppingFood = item.shoppingFood[j];
          shoppingFood.drinksChildren = shoppingFood.drinksChildren.filter(
            (d) => {
              return d.id == shoppingFood.drinksChildrenChecked;
            }
          );
          shoppingFood.drinks = undefined;

          for (let k = 0; k < shoppingFood.foods.length; k++) {
            let food = shoppingFood.foods[k];
            food.optionList = food.optionList.filter((f) => {
              return food.checked.indexOf(f.id) != -1;
            });
            // if (food.type == "Radio") {
            //   food.optionList = food.optionList.filter((f) => {
            //     return food.checked == f.id;
            //   });
            // } else {
            //   food.optionList = food.optionList.filter((f) => {
            //     return food.checked.indexOf(f.id) != -1;
            //   });
            // }
          }
          for (let k = 0; k < shoppingFood.sideDishList.length; k++) {
            let sideDish = shoppingFood.sideDishList[k];

            sideDish.optionList = sideDish.optionList.filter((o) => {
              for (let l = 0; l < shoppingFood.foods.length; l++) {
                const food = shoppingFood.foods[l];
                if (food.checked.indexOf(o.id) != -1) {
                  return true;
                }
                // if (food.type == "Radio") {
                //   if (food.checked == o.id) {
                //     return true;
                //   }
                // } else {
                //   if (food.checked.indexOf(o.id) != -1) {
                //     return true;
                //   }
                // }
              }
              if (o.id == shoppingFood.drinksChildrenChecked) {
                return true;
              }
              return false;
            });
          }

          shoppingFood.sideDishList = shoppingFood.sideDishList.filter((s) => {
            return s.optionList.length > 0;
          });
        }
      }

      //ticket数据结构创建
      let requests = [];
      let amount = 0; //订单金额

      let staffResponse = false;
      let posOrderType = this.orderTypeList.find(
        (order) => order.displayName == this.orderType
      );
      if (this.phone) {
        staffResponse = true;
      }
      for (let i = 0; i < this.orderData.length; i++) {
        let item = this.orderData[i];
        console.log("this.orderData[i]==============", item);

        amount += item.total;

        let serviceKey = item.mealPeriod + " - " + item.shoppingFood[0].name;
        if (item.shoppingFood.length > 1) {
          serviceKey += ", etc";
        }

        let requestTime = "";
        if (!item.time) {
          return;
        }
        if (item.time.search("ASAP") != -1) {
          requestTime = moment(now.getTime() + 1000 * 60 * item.ticketTime)
            .tz(localStorage.getItem("timeZone"))
            .format("YYYY/MM/DD h:mm A");
        } else {
          if (item.time.indexOf("Today") != -1) {
            requestTime = item.time.replace("Today", today);
          } else if (item.time.indexOf("Tomorrow") != -1) {
            requestTime = item.time.replace("Tomorrow", tomorrow);
          } else {
            requestTime = item.time;
          }
        }
        //补全订单信息
        item.orderId = this.orderId;
        item.orderTime = this.orderTime;
        item.orderType =
          this.posConfig.posBridge == "HVT"
            ? this.orderType
            : this.orderTypeList.find(
              (order) => order.displayName == this.orderType
            ).id;
        // item.orderType = this.orderType;
        item.payMethod = this.payMethod;
        item.roomNum = this.room;
        item.chargeRoom = this.chargeRoom;
        item.lastName = this.name;
        item.make = this.make;
        item.license = (this.license == 'To be updated') ? "": this.license;
        item.remarks = this.remarks;
        item.guestNum = this.guestNum;
        item.locationType = this.locationType;
        item.phone = this.phone;
        item.staffResponse = staffResponse;

        // 停车 Parking 支付参数
        if (this.locationType === "Parking") {
          item = Object.assign({}, item, {
            fromDate: item.shoppingFood[0].fromDate,
            fromTime: item.shoppingFood[0].fromTime,
            toDate: item.shoppingFood[0].toDate,
            toTime: item.shoppingFood[0].toTime,
            mobileChargeName: item.shoppingFood[0].mobileChargeName,
          });
        }

        if (this.posConfig.posBridge == "HVT") {
          // --------------- is HVT ----------------------

          let req = {
            createTime: createTime.utc().format(),
            hotelDeptId: localStorage.getItem("hotelDeptId"),
            inquiryNum: this.guestNum,
            requestTime: moment
              .tz(
                moment(requestTime).format("YYYY-MM-DD HH:mm"),
                localStorage.getItem("timeZone")
              )
              .utc()
              .format(),
            roomNumber: this.room,
            // serviceKey: "Room Service - " + item.mealPeriod,
            // serviceKey: item.mealPeriod,
            serviceKey: serviceKey,
            serviceType: "",
            orderId: this.orderId,
            notes: "",
            foodDetail: JSON.stringify(item),
            phone:this.phone
          };
          if (this.remarks) {
            req.notes = this.remarks;
          }
          requests.push(req);
          // --------------- is HVT END ----------------------
        } else {
          //todo 提前点餐 加时间、不提前点餐  不加时间

          item.readyTime = moment.tz(
            moment(requestTime).format("YYYY-MM-DD HH:mm:ss"),
            localStorage.getItem("timeZone")
          );
          item.fireTime = moment(
            item.readyTime.valueOf() -
            1000 * 60 * this.posConfig.sendTicketToPos || 30
          ).tz(localStorage.getItem("timeZone"));

          let foodItems = [];
          item.shoppingFood.forEach((food) => {
            let modifiers = [];
            // drink ID
            if (food.drinksChildrenChecked != "") {
              let drinkId =
                food.drinksChildren.find(
                  (item) => item.id == food.drinksChildrenChecked
                ).omnId || null;
              if (drinkId) {
                modifiers.push({ id: drinkId });
              }
            }
            // option
            if (food.foods.length > 0) {
              food.foods.forEach((foodItem) => {
                // 如果是单选
                if (typeof foodItem.checked == "string") {
                  let id =
                    foodItem.optionList.find(
                      (option) => option.id == foodItem.checked
                    ).omnId || null;
                  if (id) {
                    modifiers.push({
                      modifier: id,
                      // comment: food.remark,
                    });
                  }
                }
                // 多选
                else {
                  foodItem.checked.forEach((checkedID) => {
                    let id =
                      foodItem.optionList.find(
                        (option) => option.id == checkedID
                      ).omnId || null;
                    if (id) {
                      modifiers.push({
                        modifier: id,
                        // comment: food.remark,
                      });
                    }
                  });
                }
              });
            }

            let arr = this.jiequ(food.remark, 20);
            for (let j = 0; j < arr.length; j++) {
              const element = arr[j];
              if (food.messageModifierId) {
                modifiers.push({
                  modifier: food.messageModifierId,
                  comment: element,
                });
              }
            }

            foodItems.push({
              // comment: food.remark,
              menu_item: food.omnId,
              quantity: food.num,
              // price_per_unit: parseFloat(
              //   (parseFloat(food.fullPrice) * 100).toFixed(0)
              // ),
              modifiers,
            });
          });

          //serviceCharges
          let serviceCharges = [];
          item.feeList.forEach((tax) => {
            if (tax.omnId) {
              let charge = {
                service_charge: tax.omnId,
              };
              if (tax.open) {
                if (tax.unit == "$") {
                  charge.amount = tax.value * 100;
                } else {
                  charge.amount = tax.feeValue * 100;
                }
              }
              serviceCharges.push(charge);
            }
          });

          let params = {
            hotelId: this.hotelId,
            // autoSend: item.isAllowAdvanced,
            isAllowAdvanced: item.isAllowAdvanced,
            autoSend: true,
            guestCount: item.guestNum,
            // name: item.mealPeriod,
            // name: serviceKey,   pos的secviceKey带着check num
            name: item.mealPeriod + " - Check ",
            // readyDate: item.readyTime.format("YYYY-MM-DD"),
            // readyTime: item.readyTime.format("HH:mm:ss"),
            // fireDate: item.fireTime.format("YYYY-MM-DD"),
            // fireTime: item.fireTime.format("HH:mm:ss"),
            foodDetails: JSON.stringify(item),
            roomNumber: item.roomNum,
            lastName: this.name,
            // table: '100',
            revenueCenter: item.shoppingFood[0].revenueCenter,
            orderType: item.orderType,
            hotelDeptId: localStorage.getItem("hotelDeptId"),
            requestTime: moment
              .tz(
                moment(requestTime).format("YYYY-MM-DD HH:mm"),
                localStorage.getItem("timeZone")
              )
              .utc()
              .format(),
            notes: this.remarks ? this.remarks : "",
            table: this.roomList.find(
              (item) => item.name.toUpperCase() == this.room.toUpperCase()
            ).id,
            // roomNumber: item.roomNum,
            // priceLevel:this.posConfig.roomServiceKeyWords,

            menuItems: foodItems,
            serviceChargesArray: serviceCharges,
            chargeRoom: this.chargeRoom,
            phone: item.phone,
            staffResponse: item.staffResponse,
          };
          if (this.discount && item.promoFee) {
            if (this.discount.openValue) {
              params.omnDiscounts = [
                {
                  discount: this.discount.omnId,
                  value: this.discount.openValue * 100,
                },
              ];
            } else {
              params.omnDiscounts = [
                {
                  discount: this.discount.omnId,
                },
              ];
            }
          }
          if (this.posConfig.posModel == "Micros3700") {
            // params.fireDate = item.readyTime.format("YYYY-MM-DD");
            // params.fireTime = item.readyTime.format("HH:mm:ss");
          } else {
            params.readyDate = item.readyTime.format("YYYY-MM-DD");
            params.readyTime = item.readyTime.format("HH:mm:ss");
          }
          requests.push(params);
        }
      }

      if (this.posConfig.posBridge == "HVT") {
        // ------------------ is HVT ----------------------
        var tickets = {
          ticket: {
            requests: requests,
          },
        };
        postAction(this.url.createNewTickets, tickets)
          .then((res) => {
            if (res.success) {
              this.orderData.forEach(
                (item, index) =>
                (item.orderId =
                  res.data[index].name.substring(0, 8) +
                  "-" +
                  res.data[index].name.substring(8))
              );
              // for (let i = 0; i <= this.orderData.length; i++) {
              //   this.orderData[i].orderId = res.data[i]
              // }
              this.addOrder(this.orderData);
              this.$refs.thankPopup.init(this.orderData, this.orderTime);
              this.saveLoading = false;
            } else {
              this.saveLoading = false;
              Toast({
                message: `Oops... something happened. Please try again later.`,
                className: "toast",
                duration: 3000,
                getContainer: () => {
                  return this.$refs.home;
                },
              });
            }
          })
          .catch((err) => {
            this.saveLoading = false;
            Toast({
              // message: 'Oops... something happened. Please try again later. ',
              message: `Oops... something happened. Please try again later.`,
              duration: 3000,
              className: "toast",
              getContainer: () => {
                return this.$refs.home;
              },
            });
          });
      } else {
        if (this.isCreditcardPayment() && amount > 0) {
          let that = this;
          // postAction(`${that.url.logMessage}`, {
          //   message: "1111",
          // }).then((r) => {});
          getAction(
            `${this.url.getElavonToken}?firstName=${this.firstName}&lastName=${this.name}&amount=${amount}&hotelId=${this.hotelId}`
          )
            .then((res) => {
              this.saveLoading = false;
              if (res.status == "0") {
                const { googlePay } = JSON.parse(
                  localStorage.getItem("posConfig")
                );
                console.log("googlePay", googlePay);
                if (googlePay) {
                  // 三方跳转支付
                  this.paymentWayHref(requests, res.msg); // location 跳转支付方式
                } else {
                  this.paymentWayCurrent(requests, res.msg);
                  // for (let i = 0; i < requests.length; i++) {
                  //   let req = requests[i];
                  //   req.sslTxnId = "00000000000000000";
                  // }
                  // that.openTicket(requests);
                }
              } else {
                this.saveLoading = false;
              }
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          this.openTicket(requests);
        }
      }
    },

    paymentWayHref(requests, token) {
      this.requests = requests;
      window.sessionStorage.setItem(
        "paymentStorageDara",
        JSON.stringify(this.$data)
      );
      let hppUrl = `${process.env.VUE_APP_PAYMENT_URL}/hosted-payments?ssl_txn_auth_token=${token}`;
      // hppUrl = "http://10.16.33.236:1314/#/test";
      window.location.replace(hppUrl.replace(/\+/g, "%2B"));
    },

    paymentWayCurrent(requests, token) {
      let that = this;
      let paymentFields = {
        ssl_txn_auth_token: token,
      };
      let callback = {
        onError: function (error) {
          that.saveLoading = false;
          // console.log("error", error);
          postAction(`${that.url.logMessage}`, {
            message: JSON.stringify(error),
          }).then((r) => { });
        },
        onCancelled: function () {
          that.saveLoading = false;
          // console.log("cancelled", "");
        },
        onDeclined: function (response) {
          that.saveLoading = false;
          console.log("declined", response);
          postAction(`${that.url.logMessage}`, {
            message: JSON.stringify(response),
          }).then((r) => { });
        },
        onApproval: function (response) {
          // console.log("approval", response);
          postAction(`${that.url.logMessage}`, {
            message: JSON.stringify(response),
          }).then((r) => { });
          for (let i = 0; i < requests.length; i++) {
            let req = requests[i];
            req.sslTxnId = response.ssl_txn_id;
          }
          // console.log(response);
          that.openTicket(requests);
        },
      };
      window.myFunction(paymentFields, callback);
    },
    getRoomName() {
      let target = this.roomList.find((item) => item.id == this.room);
      if (target) {
        return target.name;
      } else {
        return "";
      }
    },
    getRoomDisplayName() {
      let target = this.roomList.find((item) => item.name == this.room);
      if (target) {
        return target.abbreviation ? target.abbreviation : this.room;
      } else {
        return this.room;
      }
    },
    openTicket(data) {
      this.saveLoading = true;
      let _data = data.map((item) => {
        return {
          ...item,
          user: localStorage.getItem("staffUserName"),
          autoClosePaid:
            (localStorage.getItem("autoClosePaid") == "true" ? true : false) &&
            this.isCreditcardPayment(),
        };
      });
      postAction(`${this.url.openTicket}?hotelId=${this.hotelId}`, _data)
        .then((res) => {
          if (res.msg == "SUCCESS") {
            if (res.data) {
              this.orderData.forEach(
                (item, index) =>
                (item.orderId =
                  res.data[index].substring(0, 8) +
                  "-" +
                  res.data[index].substring(8))
              );
              // for (let i = 0; i <= this.orderData.length; i++) {
              //   this.orderData[i].orderId = res.data[i]
              // }
              this.removeDiscount();
              this.addOrder(this.orderData);
              this.$refs.thankPopup.init(this.orderData, this.orderTime);
              this.saveLoading = false;
              window.sessionStorage.removeItem("paymentStorageDara");
            }
          } else {
            Toast({
              // message: `Oops... something happened. Please try again later.`,
              message: res.msg,
              className: "toast",
              duration: 8000,
              getContainer: () => {
                return this.$refs.home;
              },
            });
            this.saveLoading = false;
          }
        })
        .catch((err) => {
          this.saveLoading = false;
        });
    },
    ok() {
      let query = {};
      if (this.queryRoom) {
        query.room = this.queryRoom;
      }
      if (this.locationType) {
        query.location = this.locationType;
      }
      if (!this.$route.query.room && !this.locationType) {
        this.$router.replace({ path: "/myOrder" });
      } else {
        this.$router.replace({
          path: "/myOrder",
          query: query,
        });
      }
    },
    jiequ(str, n) {
      var strArr = [];
      for (var i = 0, l = str.length; i < l / n; i++) {
        var a = str.slice(n * i, n * (i + 1));
        strArr.push(a);
      }
      return strArr;
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 100%;

  /deep/ .van-toast__text {
    word-break: normal;
  }

  .word-break {
    word-break: normal;
  }

  // height: 100%;
  background: #f5f4f4;
  display: flex;
  flex-flow: column;
  align-items: center;

  /deep/ .van-nav-bar {
    background-color: $primary-color;
    height: 35pt;
    width: 100%;

    .van-nav-bar__title {
      color: #fff;
      font-size: 14pt;
    }

    .van-nav-bar__content {
      height: 100%;
    }
  }

  .content {
    width: calc(100% - 12pt);
    // height: calc(100% - 97pt);
    height: calc(100% - 120pt);
    // padding: 10pt;
    text-align: left;
    padding-bottom: 20pt;
    overflow: auto;

    .content-item {
      background: #fff;
      border-radius: 7pt;
      margin-top: 10pt;

      .content-title {
        font-size: 13pt;
        padding: 10pt 10pt 0 10pt;
        color: #000000;
        font-size: 13pt;
        border-radius: 3pt;
        font-weight: bold;
        white-space: nowrap;
        font-family: PingFangSC-Regular, PingFang SC;
      }

      .item-content {
        padding: 10pt;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .name {
          font-size: 12pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b2e36;
        }

        .price {
          font-size: 11pt;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: normal;
          color: #636464;
          margin-left: 10pt;
        }

        /deep/ .ant-select-focused .ant-select-selection,
        .ant-select-selection:focus,
        .ant-select-selection:active {
          border: 1pt solid #979797;
          box-shadow: none;
        }

        /deep/ .ant-select-open .ant-select-selection {
          border: 1pt solid #979797;
          box-shadow: none;
        }

        /deep/ .ant-select-selection {
          border: 1pt solid #979797;
          border-radius: 0;
        }

        /deep/ .ant-input {
          border: 1pt solid #979797;
          border-radius: 0;
        }

        /deep/ .ant-input:hover {
          border: 1pt solid #979797;
          box-shadow: none;
        }
      }

      .item-content::after {
        position: absolute;
        box-sizing: border-box;
        content: " ";
        pointer-events: none;
        right: 16px;
        bottom: 0;
        left: 16px;
        border-bottom: 1px solid #ebedf0;
        transform: scaleY(0.5);
      }

      /deep/ .van-field__label {
        color: #323233;
      }

      /deep/ .ant-form-item-label>label {
        color: #323233;
        font-size: 11pt;
      }

      /deep/ .ant-form-item {
        display: flex;
        align-items: center;
        margin-bottom: 0pt;

        .ant-form-item-label {
          width: 110pt;
          text-align: left;
          line-height: 10pt;
          padding: 0px;
        }

        .ant-form-item-control-wrapper {
          flex: 1;
        }
      }

      .border {
        border-bottom: 1pt solid #dcdcdc;
        padding: 5pt 10pt;
      }

      /deep/ .van-cell {
        border-radius: 7pt;
        padding: 8pt 10pt;
        overflow: hidden;
        color: #323233;
        font-size: 12pt;
        line-height: 13pt;

        .van-cell__title {
          height: 22px;
          line-height: 22px;
        }

        .van-cell__value {
          line-height: 22px;
        }

        .van-field__control {
          text-align: right;
        }
      }

      /deep/ .cell-close {
        .van-field__control {
          text-align: right;
        }
      }
    }

    .payLater {
      .tips {
        font-size: 10pt;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: normal;
        // color: #535353;
        color: #979797;
        margin-bottom: 4pt;
      }

      .title {
        font-size: 12pt;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #353535;
        padding: 5pt 0pt;
      }

      /deep/ .van-cell {
        margin: 10pt 0;
        padding: 0pt;
        border-bottom: none;
        border-radius: 0pt;
        overflow: hidden;
        color: #323233;
        font-size: 12pt;
        line-height: 13pt;

        .van-field__value {
          border: 1pt solid #979797;
        }

        .van-field__label {
          width: 50%;
          font-size: 12pt;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #353535;
          display: flex;
          align-items: center;
        }

        .van-field__control {
          padding: 5pt;
          text-align: left !important;
        }
      }

      /deep/ .van-cell::after {
        display: none;
      }
    }
  }

  .bottom {
    .btn {
      height: 30pt;
      border-radius: 5pt;

      /deep/ .van-button__text {
        font-size: 12pt;
      }
    }
  }

  .btn-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8pt;
    margin-top: 1em;

    .decline-btn {
      background-color: #fff;
      border-color: $primary-color;
      color: $primary-color;
    }
  }

  .btn {
    background-color: $primary-color;
    border-color: $primary-color;
    color: #fff;
  }

  .toast {
    word-break: normal;
  }
}

.divider {
  border-bottom: 1.5px solid #bfbfbf;
  width: calc(100% - 20pt);
  margin-left: 10pt;
  margin-top: -1px;
}

.bold-divider-cell {
  &:after {
    border-bottom: 2px solid #bfbfbf !important;
  }
}

.no-time-period {
  /deep/ .van-cell__value {
    font-weight: bold;
  }
}

/deep/ .van-dialog__header {
  border-bottom: 1pt solid #bcbcbc;
}

/deep/ .removeAfter {
  label::after {
    content: "" !important;
  }
}

.license-plate {
  line-height: 20px;

  .license-plate-tips {
    font-size: 10pt;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: normal;
    color: #979797;
  }

}

.checkbox-text {
  color: #323233;
  font-size: 11pt;
  line-height: 40px;
  padding-right: 10px;
}
</style>
